const POINTSMAP_CONFIG_PROD = {
  pointsmap_uri: 'https://pointsmap.com',
  graphql_api_uri: 'https://graphql.pointsmap.com/graphql',
};

const POINTSMAP_CONFIG_DEV = {
  pointsmap_uri: 'https://dev.pointsmap.com',
  graphql_api_uri: 'http://localhost:4000/graphql',
};

console.log({ node_env: process.env.NODE_ENV });

export default (process.env.NODE_ENV === 'production' ? POINTSMAP_CONFIG_PROD : POINTSMAP_CONFIG_DEV);
