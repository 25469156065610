import gql from 'graphql-tag';

export const GET_POINTS = gql`
  query getPointsForAccount($accountId: ID!, $limit: Int, $page: Int) {
    points(accountId: $accountId, limit: $limit, page: $page) {
      count
      total
      nodes {
        id
        label
        enabled
        folderId
        createdOn
        lastModified
        primaryPhotoUrl
        parentSubmapId
        groupId
        lat
        lng
        xPercent
        yPercent

        account {
          codeName
        }

        category {
          label
        }
      }
    }
  }
`;
