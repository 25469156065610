import React, { useState, useRef, useEffect } from 'react';
import matchSorter from 'match-sorter';

export const useFilter = (originalData, loading, error) => {
  const [filteredData, setFilteredData] = useState([]);

  useEffect(() => {
    if (!loading && !error) {
      // When loaded, set the filtered points to the loaded points
      setFilteredData(originalData);
    }
  }, [loading]);

  const filterData = searchString => {
    setFilteredData(
      matchSorter(originalData, searchString, {
        keys: ['label', 'id', 'account.codeName', 'category.label'],
      })
    );
  };
  return [filteredData, filterData, setFilteredData];
};

export default useFilter;
