import React from 'react';

import TableCell from './TableCell';

export const YesOrNo = ({ value }) => {
  return value ? (
    <TableCell style={{ color: '#2c2' }}>Yes</TableCell>
  ) : (
    <TableCell style={{ color: '#c22' }}>No</TableCell>
  );
};

export default YesOrNo;
