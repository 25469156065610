import React, { createContext, useState, useRef } from 'react';
import { range } from 'lodash';

export const TableContext = createContext();

export const TableContextProvider = props => {
  const tableRef = useRef(0);

  const [selectedPoints, setSelectedPoints] = useState({});
  const [allSelected, setAllSelected] = useState(false);
  const [lastSelected, setLastSelected] = useState();

  const toggleSelection = (id, forceSelectAs) => {
    if (Array.isArray(id)) {
      const newValue = typeof forceSelectAs === 'undefined' ? !selectedPoints[itemId] : forceSelectAs;
      const newSelectedPoints = { ...selectedPoints };

      id.forEach(itemId => {
        newSelectedPoints[itemId] = newValue;
        setLastSelected(itemId);
      });
      return setSelectedPoints(newSelectedPoints);
    } else {
      const newSelectedPoints = { ...selectedPoints };
      const newValue = typeof forceSelectAs === 'undefined' ? !selectedPoints[id] : forceSelectAs;
      newSelectedPoints[id] = newValue;
      setSelectedPoints(newSelectedPoints);
      // setSelectedPoints({ ...selectedPoints, [id]: newValue });
      return setLastSelected(id);
    }
  };

  const toggleSelectionByViewIndex = (viewIndex, forceSelectAs) => {
    const wrappedInstance = tableRef.current.getWrappedInstance();
    const currentRecords = wrappedInstance.getResolvedState().sortedData;

    const page = wrappedInstance.state.page;
    const pageSize = wrappedInstance.state.pageSize;

    const firstOnPage = page * pageSize;
    const lastOnPage = page * pageSize + pageSize;

    const currentPoints = currentRecords.slice(firstOnPage, lastOnPage);
    return toggleSelection(currentPoints[viewIndex]._original.id, forceSelectAs);
  };

  const getItemsOnPage = () => {
    const wrappedInstance = tableRef.current.getWrappedInstance();
    const currentRecords = wrappedInstance.getResolvedState().sortedData;

    const page = wrappedInstance.state.page;
    const pageSize = wrappedInstance.state.pageSize;

    const firstOnPage = page * pageSize;
    const lastOnPage = page * pageSize + pageSize;

    return currentRecords.slice(firstOnPage, lastOnPage);
  };

  const toggleSelectionByRange = (min, max, forceSelectAs = true) => {
    const actualMax = max > min ? max + 1 : max - 1;
    const viewIndexRange = range(min, actualMax);
    const currentItems = getItemsOnPage();

    const itemIds = viewIndexRange.map(viewIndex => currentItems[viewIndex]._original.id);

    toggleSelection(itemIds, forceSelectAs);
  };

  const selectFromLast = (index, forceSelectAs = true) => {
    const currentItemIds = getItemsOnPage().map(item => item._original.id);
    const lastSelectedIndex = currentItemIds.indexOf(lastSelected);

    toggleSelectionByRange(lastSelectedIndex, index, forceSelectAs);
  };

  const toggleSelectAll = () => {
    const wrappedInstance = tableRef.current.getWrappedInstance();
    const currentRecords = wrappedInstance.getResolvedState().sortedData;

    const page = wrappedInstance.state.page;
    const pageSize = wrappedInstance.state.pageSize;

    const firstOnPage = page * pageSize;
    const lastOnPage = page * pageSize + pageSize;

    const currentPoints = currentRecords.slice(firstOnPage, lastOnPage);

    if (allSelected) {
      setSelectedPoints({});
      setAllSelected(false);
    } else {
      let newSelectedPoints = {};
      currentPoints.forEach(({ _original: { id } }) => {
        newSelectedPoints[id] = true;
      });

      setSelectedPoints(newSelectedPoints);
      setAllSelected(true);
    }
  };

  const isSelected = id => !!selectedPoints[id];

  return (
    <TableContext.Provider
      value={{
        selectedPoints,
        toggleSelection,
        setSelectedPoints,
        isSelected,
        lastSelected,
        allSelected,
        tableRef,
        toggleSelectAll,
        toggleSelectionByViewIndex,
        toggleSelectionByRange,
        selectFromLast,
      }}
      {...props}
    />
  );
};

export default TableContext;
