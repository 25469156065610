import react from 'react';
import styled, { css } from 'styled-components';

export const TableCell = styled.div`
  ${({ align = 'center', color = '#000', flex = true }) => css`
    color: ${color};
    text-align: ${align};

    ${flex &&
      css`
        display: flex;
        align-items: center;
        justify-content: ${align === 'center' ? 'center' : 'auto'};
        width: 100%;
        height: 100%;
      `};
  `};

  img {
    width: 40px;
    height: 40px;
    object-fit: cover;
  }
`;

export default TableCell;
