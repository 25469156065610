import React, { useState, useEffect, useContext, useRef } from 'react';
import TableCell from '../components/TableCell';
import { TableContext } from '../context/TableContext';

export const useTableData = (originalData, getColumnData) => {
  const tableContext = useContext(TableContext);
  const {
    toggleSelection,
    isSelected,
    lastSelected,
    allSelected,
    tableRef,
    toggleSelectAll,
    // setPageRows,
  } = tableContext;

  // const [columns, data] = createTableData(originalData, getColumnData, tableContext);
  const [[columns, data], setTableData] = useState(createTableData(originalData, getColumnData, tableContext));

  useEffect(() => {
    setTableData(createTableData(originalData, getColumnData, tableContext));
  }, [originalData, getColumnData, tableContext]);

  const tableProps = {
    selectType: 'checkbox',
    ref: tableRef,
    selectAll: allSelected,
    toggleAll: toggleSelectAll,
    isSelected: isSelected,
    toggleSelection: (key, shift, { id, ...row }) => {
      toggleSelection(id);
    },
    resizable: true,
    pageSizeOptions: [5, 10, 20, 25, 50, 100, ...getHighestRowCount(originalData.length)],
    data,
    columns,
    keyField: 'id',
    className: '-striped -highlight',
  };

  return { tableProps };
};

const getHighestRowCount = dataLength => {
  if (dataLength > 400) return [400];
  if (dataLength <= 150) return [];
  return [dataLength];
};

const createTableData = (pointNodes, getColumnData, tableContext) => {
  const columnData = getColumnData(pointNodes[0], tableContext, 0);
  // Format the columns for
  const columns = Object.keys(columnData).map(key => ({
    Header: key,
    accessor: key,
    id: `table-col-${key}`,
    width: 100,
    Cell: ({ value }) => <TableCell>{value}</TableCell>,
    ...columnData[key],
  }));

  // Turn { label: { data: val }} to { label: val } for each pointNode
  const data = pointNodes.map((point, pointIndex) => {
    let pointData = {};
    const pointColumns = getColumnData(point, tableContext);
    Object.keys(pointColumns).forEach((key, keyIndex) => {
      pointData[key] = pointColumns[key].data;
    });

    return { ...point, ...pointData };
  });

  return [columns, data];
};

export default useTableData;
