import React, { useContext } from 'react';
import { format, parse } from 'date-fns';
import { range } from 'lodash';

import TableCell from '../components/TableCell';
import YesOrNo from '../components/YesOrNo';
import Checkbox from '../components/Checkbox';

import PM_CONFIG from './pointsmap';

const stubData = { category: {}, account: {} };

const width = 500;
const height = 500;
const popup = {
  width,
  height,
  left: window.screen.availLeft + window.screen.availWidth / 2 - width / 2,
  top: window.screen.availTop + window.screen.availHeight / 2 - height / 2,
};

const objToWindowSpecs = obj =>
  Object.keys(obj)
    .map(key => `${key}=${obj[key]}`)
    .join(',');

// Change the 'columns' object whenever you want to add or remove a table column.
// It is set up where you can define the properties of both the columns and each cell in one place.
// The key will be the name of the column, the 'data' attribute will be whatever data you want to show in the rows.
// If you want to customize how the cell is rendered, you an pass a custom component to 'Cell' which recieves the data as the 'value' prop

// Everything else is the same as on the Columns object in 'react-table' version 6.
// More info here: https://github.com/tannerlinsley/react-table/tree/v6#columns
export const columns = (point = stubData, tableContext) => {
  return {
    Select: {
      data: point,
      width: 28,
      resizable: false,
      sortable: false,
      Header: props => (
        <Checkbox onClick={e => handleCheckboxClick(props, tableContext, e, true)} checked={tableContext.allSelected} />
      ),
      Cell: props => (
        <TableCell>
          <Checkbox
            onClick={e => handleCheckboxClick(props, tableContext, e, false)}
            checked={tableContext.isSelected(props.value.id)}
          />
        </TableCell>
      ),
    },
    Label: {
      data: point.label,
      width: 200,
      Cell: ({ value }) => <TableCell align="left">{value}</TableCell>,
    },

    'Point ID': { data: parseInt(point.id) },

    Category: {
      data: point.category.label,
      width: 200,
      Cell: ({ value }) => <TableCell align="left">{value}</TableCell>,
    },

    Visible: { data: point.enabled, Cell: ({ value }) => <YesOrNo value={value} /> },

    Photo: {
      data: point.primaryPhotoUrl ? { ...point } : null,
      Cell: ({ value }) => {
        if (!value || !value.primaryPhotoUrl) return <TableCell color="#777">N/A</TableCell>;
        return (
          <TableCell>
            <img
              src={`${PM_CONFIG.pointsmap_uri}/${value.account.codeName}/data/photos/${value.primaryPhotoUrl}`}
              alt={value.label}
            />
          </TableCell>
        );
      },
    },

    'Created On': {
      data: point.createdOn || null,
      width: 200,
      Cell: ({ value, ...props }) => {
        if (!value) return <TableCell color="#777">N/A</TableCell>;
        const date = new Date(parse(value, 'YYYY-MM-DD'));
        return <TableCell>{format(date, 'MMM D YYYY')}</TableCell>;
      },
    },

    Modified: {
      data: point.lastModified || null,
      Cell: ({ value, ...props }) => {
        if (!value) return <TableCell color="#777">N/A</TableCell>;
        const date = new Date(parse(value, 'YYYY-MM-DD'));
        return <TableCell>{format(date, 'MMM D YYYY')}</TableCell>;
      },
    },

    'Edit Point': {
      sortable: false,
      Cell: props => (
        <TableCell>
          <a
            target="_blank"
            rel="noopener noreferrer"
            href={`${PM_CONFIG.pointsmap_uri}/points_edit.php?id=${props.original.id}`}
            style={{ color: '#2c2' }}
          >
            Edit
          </a>
        </TableCell>
      ),
    },

    'Delete Point': {
      sortable: false,
      Cell: props => (
        <TableCell>
          <a
            target="popup"
            rel="noopener noreferrer"
            href={`${PM_CONFIG.pointsmap_uri}/points_delete.php?id=${props.original.id}&noHeader=1`}
            onClick={() => {
              window.open(
                `${PM_CONFIG.pointsmap_uri}/points_delete.php?id=${props.original.id}&noHeader=1`,
                'popup',
                // `width=${popup.width},height=${popup.height},right=${popup.right},left=${popup.left}`
                objToWindowSpecs(popup)
              );
              return false;
            }}
            style={{ color: '#c22' }}
          >
            Delete
          </a>
        </TableCell>
      ),
      width: 100,
    },
  };
};

export default columns;

const handleCheckboxClick = (props, tableContext, e, selectAllVisible = false) => {
  if (selectAllVisible) {
    return tableContext.toggleSelectAll();
  }

  if (e.shiftKey) {
    return tableContext.selectFromLast(props.viewIndex, tableContext.isSelected(tableContext.lastSelected));
  }

  tableContext.toggleSelection(props.value.id);
};
