import React from 'react';
import Loader from 'react-spinners/DotLoader';

const Loading = ({ loading }) => {
  return (
    <div>
      <Loader sizeUnit={'px'} size={150} color={'#123abc'} loading={loading} />
    </div>
  );
};

export default Loading;
