import React from 'react';
import styled, { css } from 'styled-components';
import ReactTable from 'react-table';
import selectTableHOC from 'react-table/lib/hoc/selectTable';
import useTableData from '../hooks/useTableData';
const SelectTable = selectTableHOC(ReactTable);

const Table = ({ data, columns }) => {
  const { tableProps } = useTableData(data, columns);
  return <TableContainer {...tableProps} />;
};

export default Table;

const TableContainer = styled(SelectTable)`
  font-family: 'Helvetica', 'Arial', sans-serif;

  /* Hide the default checkboxes because we're using our own */
  .rt-th:first-child {
    display: none;
  }
  .rt-tr-group .rt-td:first-child {
    display: none;
  }

  .rt-tbody input[type='checkbox'] {
    width: 100%;
    height: 100%;
  }

  .-pagination button.-btn {
    background: #0ab;
    color: black;

    &:not([disabled]):hover {
      background: #067;
      color: black;
    }

    &:disabled {
      background: #ccc;
    }
  }
`;
