import './index.css';
import React from 'react';
import { render } from 'react-dom';
import { ApolloProvider } from 'react-apollo-hooks';

import { client } from './lib/apollo';
import App from './components/App';
import { TableContextProvider } from './context/TableContext';

const pointsListRoot = document.querySelector('#points-list');
const { dataset } = pointsListRoot;

render(
  <ApolloProvider client={client}>
    <TableContextProvider>
      <App {...dataset} />
    </TableContextProvider>
  </ApolloProvider>,
  pointsListRoot
);
