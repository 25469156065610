import React from 'react';
import styled, { css } from 'styled-components';

const Checkbox = ({ checked, children, ...props }) => {
  return (
    <StyledCheckbox role="checkbox" checked={checked} {...props}>
      {getCheckmark(checked)}
    </StyledCheckbox>
  );
};

export default Checkbox;

const StyledCheckbox = styled.button`
  width: 18px;
  height: 18px;
  box-sizing: border-box;
  border: 1px solid #666;
  border-radius: 4px;
  text-align: center;
  padding: 0px;
  font-size: 1rem;
  background: ${({ checked }) => getCheckboxColor(checked)};
`;

const getCheckboxColor = value => {
  if (typeof value === 'boolean') return value ? '#ccc' : '#fff';
  switch (value) {
    case 2:
      return '#ccc';
    case 1:
      return '#777';
    default:
      return '#fff';
  }
};

const getCheckmark = value => {
  if (typeof value === 'boolean') return value ? '✓' : '';
  switch (value) {
    case 2:
      return '✓';
    case 1:
      return '-';
    default:
      return '';
  }
};
