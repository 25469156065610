import 'react-table/react-table.css';

import React, { useRef, useContext } from 'react';
import { useQuery } from 'react-apollo-hooks';
import styled from 'styled-components';

import { GET_POINTS } from '../queries';
import tableColumns from '../config/tableColumns';
import useFilter from '../hooks/useFilter';
import Table from './Table';
import Loading from './Loading';

const App = ({ accountId }) => {
  // Get data from graphql server
  const {
    data: { points = {} },
    error,
    loading,
  } = useQuery(GET_POINTS, {
    variables: {
      accountId,
      limit: 2000,
    },
  });

  const [filteredData, filterData] = useFilter(points.nodes, loading, error);

  const searchBox = useRef(0);

  const handeFormSubmit = e => {
    e.preventDefault();
    filterData(searchBox.current.value);
  };

  if (loading)
    return (
      <CenteredContainer>
        <Loading loading={true} />{' '}
      </CenteredContainer>
    );
  if (error) {
    return (
      <CenteredContainer>
        Error loading data: <p>{error.message}</p>
      </CenteredContainer>
    );
  }

  return (
    <AppContainer>
      <ControlForm onSubmit={handeFormSubmit}>
        <h2>Actions</h2>

        <SearchBox name="searchBox" id="searchBox" placeholder="Search" type="text" ref={searchBox} />
      </ControlForm>
      <Table data={filteredData} columns={tableColumns} />
    </AppContainer>
  );
};

export default App;

const AppContainer = styled.main`
  font-family: 'Helvetica', 'Arial', sans-serif;

  div {
    box-sizing: border-box;
    margin: 0;
    padding: 0;
  }
`;

const CenteredContainer = styled(AppContainer)`
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const ControlForm = styled.form`
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  margin-bottom: 10px;
  flex-wrap: wrap;
`;

const SearchBox = styled.input`
  padding: 10px;
  border: none;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24);
  width: 300px;
  max-width: 100vw;
`;
